import axios from "axios";
import lowauthHeader from "./lowauth-header";
const cfg = {
  appId: process.env.VUE_APP_DISCORD_APP_ID,
  appSecret: process.env.VUE_APP_DISCORD_APP_SECRET,
  appRedirectUri: process.env.VUE_APP_DISCORD_LIVE_URL,
  //appAuthUri: process.env.VUE_APP_DISCORD_LOCAL_REDIRECT_URL,
  appAuthUri: process.env.VUE_APP_DISCORD_LIVE_REDIRECT_URL,
  localLoginUri: "http://localhost:8080/login",
  liveLoginUri: "https://member.undisputednoobs.com/login",
};

class DiscordServices {
  async Registration(code) {
    try {
      const response = await axios.post(
        "https://discordapp.com/api/oauth2/token",
        `client_id=${cfg.appId}&client_secret=${cfg.appSecret}&grant_type=authorization_code&code=${code}&redirect_uri=${cfg.appAuthUri}`
      );
      const config = {
        headers: { Authorization: `Bearer ${response.data.access_token}` },
      };
      try {
        const response_1 = await axios.get(
          "https://discordapp.com/api/users/@me/guilds/714576048398008371/member",
          config
        );
        return { success: true, data: response_1.data };
      } catch (error) {
        return {
          success: false,
          message: "Please join our Discord server and try again.",
        };
      }
    } catch (error_1) {
      return {
        success: false,
        message: "Error while retrieving your data, please try again.",
      };
    }
  }

  async Login(code) {
    try {
      var isDev = window.location.href.includes("localhost");

      const response = await axios.post(
        "https://discordapp.com/api/oauth2/token",
        `client_id=${cfg.appId}&client_secret=${
          cfg.appSecret
        }&grant_type=authorization_code&code=${code}&redirect_uri=${
          isDev ? cfg.localLoginUri : cfg.liveLoginUri
        }`
      );
      ////console.log(response);
      var accesstoken = response.data.access_token;
      try {
        try {
          const response_1 = await axios.post(
            "https://api-unp.sacul.cloud/auth/discordLogin",
            //"http://localhost:8081/auth/discordLogin",
            {
              accesstoken: accesstoken,
            },
            { headers: lowauthHeader() }
          );
          if (response_1.data.data.accessToken) {
            localStorage.setItem("user", JSON.stringify(response_1.data.data));
          }
          return { success: true, data: response_1.data.data };
        } catch (error) {
          return {
            success: false,
            notRegistered: error.response.data.notRegistered || true,
            message: error.response.data.message,
            error: error.response.data.error,
          };
        }
      } catch (error_1) {
        return {
          success: false,
          message: "Please join our Discord server and try again.",
        };
      }
    } catch (error_2) {
      return {
        success: false,
        message: "Error while retrieving your data, please try again.",
      };
    }
  }

  Refresh(code) {
    // Todo: Add refresh discord details
  }
}

export default new DiscordServices();
