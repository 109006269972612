<template>
  <b-row class="hp-authentication-page">
    <left-content-register />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-7 px-8 px-sm-0 pt-24 pb-48"
        >
          <Transition name="slide-fade">
            <div v-if="step === 1">
              <h1 class="mb-0 mb-sm-24">Create an account 🎉</h1>
              <p class="mt-sm-8 mt-sm-0 text-black-60">
                Let's get you registered and welcomed into the family!
              </p>

              <b-form class="mt-16 mt-sm-32 mb-8">
                <p>
                  To create an account you will have to log in via Discord.
                  <br /><br />
                  This process is mandatory, so we can make sure that you are
                  not a robot and not already registered
                  <br /><br />
                  Worried about your personal information?<br />Check out our
                  Privacy Policy below to see how we use your data.
                </p>

                <b-alert variant="warning" show>
                  <h4 class="mb-6 text-warning">
                    <strong> If you are already onboarded </strong>
                  </h4>
                  <p class="mb-0">
                    Don't worry, the Discord login will automatically retrieve
                    your data. <br />
                    Please use the discord account you registered with.
                  </p>
                </b-alert>

                <b-button
                  variant="none"
                  class="w-100 mt-16 hp-border-color-dark-30 hp-hover-border-color-dark-primary-2"
                  @click.prevent="discordLogin()"
                >
                  <i class="ri-discord-fill remix-icon text-primary-2"></i>
                  <span>Continue with Discord</span>
                </b-button>
              </b-form>

              <div class="hp-form-info">
                <span
                  class="text-black-80 hp-text-color-dark-40 hp-caption mr-4"
                >
                  Already have an account?
                </span>

                <b-link
                  class="hp-cursor-pointer text-primary-1 hp-text-color-dark-primary-1 hp-caption"
                  to="/login"
                >
                  Login
                </b-link>
              </div>

              <div class="hp-other-links mt-24">
                <a
                  href="./legal/privacy-policy"
                  class="text-black-80 hp-text-color-dark-40"
                >
                  Privacy Policy
                </a>

                <a
                  href="./legal/terms-of-use"
                  class="text-black-80 hp-text-color-dark-40"
                >
                  Terms of use
                </a>

                <a
                  href="https://undisputednoobs.freshdesk.com/support/home"
                  target="_blank"
                  class="text-black-80 hp-text-color-dark-40"
                >
                  I need help
                </a>
              </div>
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="step === 2">
              <h1 class="mb-0 mb-sm-0">
                Welcome
                {{ registration.stepOne.discordusername }}
                <b-avatar
                  :src="registration.stepOne.discordavatar"
                  size="4rem"
                ></b-avatar>
              </h1>
              <p class="mt-sm-8 mt-sm-0 text-black-60">
                To the Undisputed Noobs Membership Programme.
              </p>

              <b-form class="mt-16 mt-sm-32 mb-8">
                <b-form-group class="mb-16">
                  <b-form-textarea
                    rows="20"
                    v-bind:value="message"
                    no-resize
                    readonly
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group class="mb-16">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="registration.stepTwo.welcomeStatus"
                    name="checkbox-1"
                    value="checked"
                    unchecked-value=""
                  >
                    Check this to confirm that you have read and accepted the
                    above information
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  type="button"
                  variant="primary-5"
                  class="w-50"
                  @click.prevent="refresh()"
                >
                  Back
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="w-50"
                  @click.prevent="next()"
                  :disabled="!registration.stepTwo.welcomeStatus"
                >
                  Next
                </b-button>
              </b-form>
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="step === 3">
              <h1 class="mb-0 mb-sm-24">Some details 📝</h1>
              <p class="mt-sm-8 mt-sm-0 text-black-60">
                The information below requests basic contact information just so
                we know you are a real person. It also allows us to contact you
                if required.
              </p>

              <b-form class="mt-16 mt-sm-32 mb-8">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group class="mb-16">
                      <b-form-label><span class="text-danger">* </span>First name</b-form-label>
                      <b-form-input
                        v-model="registration.stepThree.firstName"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group class="mb-16">
                      <b-form-label><span class="text-danger">* </span>Last name</b-form-label>
                      <b-form-input
                        v-model="registration.stepThree.lastName"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group class="mb-16">
                  <b-form-label><span class="text-danger">* </span>Username</b-form-label>
                  <b-form-input
                    v-model="registration.stepThree.userName"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="mb-16">
                  <b-form-label><span class="text-danger">* </span>Phone number</b-form-label>
                  <b-form-input
                    v-model="registration.stepThree.phoneNumber"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="mb-16">
                  <b-form-label><span class="text-danger">* </span>Country</b-form-label>
                  <b-form-select
                    v-model="registration.stepThree.country"
                    :options="countryList"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-for="registerEmail"
                  class="mb-16"
                >
                <b-form-label><span class="text-danger">* </span>Email</b-form-label>
                  <b-form-input
                    v-model="registration.stepThree.email"
                    type="email"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-for="registerPassword"
                  class="mb-16"
                >
                <b-form-label><span class="text-danger">* </span>Password</b-form-label>
                  <b-form-input
                    id="registerPassword"
                    v-model="registration.stepThree.password"
                    type="password"
                  ></b-form-input>
                </b-form-group>

                <b-button
                  type="button"
                  variant="primary-5"
                  class="w-50"
                  @click.prevent="prev()"
                >
                  Back
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="w-50"
                  @click.prevent="next()"
                >
                  Next
                </b-button>
              </b-form>
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="step === 4">
              <h1 class="mb-0 mb-sm-24">
                What game(s) are you interested in? 🎮
              </h1>
              <p class="mt-sm-8 mt-sm-0 text-black-60">
                Please select the games that you are interested in playing. You
                can update this later but we recommend you take a moment and
                look into each game to ensure you want to play them. There is no
                limit. You can apply for them all if you want.
              </p>

              <b-form class="mt-16 mt-sm-32 mb-8">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-checkbox
                    v-for="option in options"
                    v-model="registration.stepFour.gamesInterestedIn"
                    :key="option.value"
                    :value="option.value"
                    :aria-describedby="ariaDescribedby"
                    name="gamesinterestedin"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  type="button"
                  variant="primary-5"
                  class="w-50"
                  @click.prevent="prev()"
                >
                  Back
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="w-50"
                  @click.prevent="next()"
                >
                  Next
                </b-button>
              </b-form>
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="step === 5">
              <h1 class="mb-0 mb-sm-24">Setting up your wallets 💳</h1>
              <p class="mt-sm-8 mt-sm-0">
                Different games <b>REQUIRE</b> different wallets to be setup.
                <br /><br />
                If you don't add your wallet correctly - it will stop your
                application process.
                <br /><br />
                Some games need a specific wallet, if you have previously
                selected a game that needs a specific wallet, you will have to
                enter it here. You can change this at any time in your
                profile once onboarded.
              </p>

              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <b-input-group
                      class="mb-2"
                      v-if="
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'sunflowerland'
                        ) ||
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'legendsofvenari'
                        ) ||
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'illuvium'
                        ) ||
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'godsunchained'
                        ) ||
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'bigtime'
                        )
                      "
                    >
                      <b-input-group-prepend is-text>
                        <a style="min-width: 70px" class="text-white text-left">
                          Metamask
                        </a>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="registration.stepFive.walletMetamask"
                      ></b-form-input>
                    </b-input-group>
                    <b-input-group
                      class="mb-2"
                      v-if="
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'axieinfinity'
                        )
                      "
                    >
                      <b-input-group-prepend is-text>
                        <a style="min-width: 70px" class="text-white text-left">
                          Ronin
                        </a>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="registration.stepFive.walletRonin"
                      ></b-form-input>
                    </b-input-group>
                    <b-input-group
                      class="mb-2"
                      v-if="
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'genopets'
                        ) ||
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'honeyland'
                        )
                      "
                    >
                      <b-input-group-prepend is-text>
                        <a style="min-width: 70px" class="text-white text-left">
                          Solana
                        </a>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="registration.stepFive.walletSolana"
                      ></b-form-input>
                    </b-input-group>
                    <b-input-group
                      class="mb-2"
                      v-if="
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'milliononmars'
                        )
                      "
                    >
                      <b-input-group-prepend is-text>
                        <a style="min-width: 70px" class="text-white text-left">
                          Wax
                        </a>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="registration.stepFive.walletWax"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                type="button"
                variant="primary-5"
                class="w-50"
                @click.prevent="prev()"
              >
                Back
              </b-button>
              <b-button
                type="button"
                variant="primary"
                class="w-50"
                @click.prevent="next()"
              >
                Next
              </b-button>
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="step === 6">
              <h1 class="mb-0 mb-sm-24">Your reason why ❓</h1>
              <b-form class="mt-16 mt-sm-32 mb-8">
                <b-form-group>
                  <p>
                    Make this real, make it worth reading and make it original.
                    We read thousands of these and can spot a template.
                    <br /><br />
                    Joining our community you agree to hold yourself and each
                    other accountable to the following core values.
                    <br /><br />
                    These are non-negotiable and everyone must align to them.
                    <br />
                    <h3 class="text-success">BE RESPECTFUL</h3>
                    <p>This is respect for yourself, as well as for others. You will be faced with disagreements, but it should never be a reason for rude behavior. Create a healthy environment by understanding one another.</p>
                    <h3 class="text-warning">STAY POSITIVE</h3>
                    <p>How you show up in challenges is just as important as when things are good. Amidst the problems and frustrations that come with it, maintain a positive mood by being contented, happy and hopeful.</p>
                    <h3 class="text-primary">HAVE FUN</h3>
                    <p>We want to keep things professional, but we also need to remember that life is for living and not to be too serious all the time. We are human after all and like in any other game, we should have fun. Life is meant to be enjoyed.</p>
                  </p>
                </b-form-group>

                <b-form-group>
                  <b-form-textarea
                    v-model="registration.stepSix.reason"
                    rows="5"
                    min-rows="5"
                    placeholder="Why do you want to be a Scholar for Undisputed Noobs?"
                    class="mb-0"
                  ></b-form-textarea>
                </b-form-group>

                <b-button
                  type="button"
                  variant="primary-5"
                  class="w-50"
                  @click.prevent="prev()"
                >
                  Back
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="w-50"
                  @click.prevent="next()"
                >
                  Next
                </b-button>
              </b-form>
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="step === 7">
              <h1 class="mb-0 mb-sm-24">Well Being 🤗</h1>
              <p class="mt-sm-8 mt-sm-0 text-black-60">
                Our mission is to protect, educate and help you grow as a person.
              </p>

              <b-form class="mt-16 mt-sm-32 mb-8">
                <b-form-group>
                  <p>
                    I would like you to take a moment and look at these and make
                    sure you understand that when you enter our community you
                    will have access to support in each of these areas.
                  </p>

                  <a @click.prevent="showModal('wellBeing')">
                    <img
                      width="100%"
                      height="100%"
                      :src="
                        require('@/assets/img/pages/authentication/well-being.png')
                      "
                    />
                    <p class="mt-sm-8 mt-sm-0 text-black-60 text-center">
                      Click to expand
                    </p>
                  </a>
                </b-form-group>

                <b-modal
                  id="wellBeing"
                  title="Well Being"
                  :hide-footer="true"
                  :hide-header="true"
                  :hide-body-padding="true"
                  :hide-close="false"
                  :width="'300%'"
                  :height="'300%'"
                  :scrollable="true"
                  size="xl"
                >
                  <div class="text-center">
                    <img
                      width="100%"
                      height="100%"
                      :src="
                        require('@/assets/img/pages/authentication/well-being.png')
                      "
                    />
                  </div>
                </b-modal>

                <b-button
                  type="button"
                  variant="primary-5"
                  class="w-50"
                  @click.prevent="prev()"
                >
                  Back
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="w-50"
                  @click.prevent="next()"
                >
                  Next
                </b-button>
              </b-form>
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="step === 8">
              <h1 class="mb-0 mb-sm-24">Additional Information 📰</h1>
              <p class="mt-sm-8 mt-sm-0 text-black-60">
                Just a few more questions to help us understand a little more
                about you so that we can serve you better.
              </p>

              <b-form class="mt-16 mt-sm-32 mb-8">
                <b-form-group>
                  <b-form-group
                    class="mb-16"
                  >
                  <b-form-label><span class="text-danger">* </span>Where did you hear about us?</b-form-label>
                    <b-form-select
                      v-model="registration.stepEight.wheredidyouhearaboutus"
                      :options="wheredidyouhearaboutus"
                      :placeholder="'- Select an option -'"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group>
                    <b-row>
                      <b-col cols="12" md="6">
                        <div>
                          <div>
                            <b-form-group
                              v-slot="{ nftgames }"
                            >
                            <b-form-label><span class="text-danger">* </span>Have you ever played NFT games before?</b-form-label>
                              <b-form-radio
                                v-model="registration.stepEight.nftGamesBefore"
                                :aria-describedby="nftgames"
                                name="nftgames-radios"
                                value="yes"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="registration.stepEight.nftGamesBefore"
                                :aria-describedby="nftgames"
                                name="nftgames-radios"
                                value="no"
                                >No</b-form-radio
                              >
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="12" md="6">
                        <div>
                          <div>
                            <b-form-group
                              v-slot="{ scholarship }"
                            >
                            <b-form-label><span class="text-danger">* </span>Have you ever been in a guild before?</b-form-label>
                              <b-form-radio
                                v-model="
                                  registration.stepEight.scholarshipBefore
                                "
                                :aria-describedby="scholarship"
                                name="scholarship-radios"
                                value="yes"
                                >Yes</b-form-radio
                              >
                              <b-form-radio
                                v-model="
                                  registration.stepEight.scholarshipBefore
                                "
                                :aria-describedby="scholarship"
                                name="scholarship-radios"
                                value="no"
                                >No</b-form-radio
                              >
                            </b-form-group>
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                    <div
                      v-if="registration.stepEight.scholarshipBefore === 'yes'"
                    >
                      <b-form-group
                      >
                      <b-form-label><span class="text-danger">* </span>If yes, what was the name of the previous guild?</b-form-label>
                        <b-form-input
                          v-model="registration.stepEight.scholarshipBeforeName"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <b-form-group
                      class="mb-16"
                    >
                    <b-form-label><span class="text-danger">* </span>How well do you consider to know the game you are applying for?</b-form-label>
                      <b-form-select
                        v-model="registration.stepEight.gameKnowledge"
                        :options="gameKnowledge"
                      ></b-form-select>
                    </b-form-group>
                  </b-form-group>

                  <b-form-group label="Referral Discord Username" class="mb-16">
                    <p class="mt-sm-8 mt-sm-0 text-black-60">
                      Did someone tell you about us? Let us know their Discord
                      ID and we might just send them a little thank you :)
                    </p>
                    <b-form-input
                      v-model="registration.stepEight.referralDiscordUsername"
                      type="text"
                      placeholder="Discord Username (Sacul#0001)"
                    ></b-form-input>
                  </b-form-group>
                </b-form-group>

                <b-button
                  type="button"
                  variant="primary-5"
                  class="w-50"
                  @click.prevent="prev()"
                >
                  Back
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="w-50"
                  @click.prevent="submit()"
                >
                  Submit my application
                </b-button>
              </b-form>
            </div>
          </Transition>

          <Transition name="slide-fade">
            <div v-if="step === 9">
              <h1 class="mb-0 mb-sm-24">Thank you for your application 🎉</h1>
              <p>
                Our team will review your application and get back to you
                shortly.
                <br />Currently, we will get back to you
                <span class="text-success">within 5 working days</span>.
                <br /><br />Your account has also been created, you will be able
                to access it once a member of our team has processed your
                application
              </p>
              <b-alert show variant="info">
                <h4 class="alert-heading">Remember</h4>
                <p>
                  You must be on our Discord server. If you leave the server in
                  the meantime your application will be cancelled.
                  <br /><br />
                  Once we are ready to process your application, we will mention
                  you on Discord.
                </p>
                <b-button
                  type="button"
                  variant="info-1"
                  class="w-100 text-dark"
                  @click.prevent="joinDiscord()"
                >
                  Join our Discord
                </b-button>
              </b-alert>
              <b-button
                type="button"
                variant="primary-1"
                class="w-100 btn-ghost"
                @click.prevent="toLogin()"
              >
                Login
              </b-button>
            </div>
          </Transition>

          <Transition name="slide-fade">
            <div v-if="step === 10">
              <h1 class="mb-0 mb-sm-24">Your account is ready 🎉</h1>
              <b-row>
                <b-col cols="12">
                  <p>
                    Your data has been successfully migrated and your account
                    has been created, you can now log in.
                  </p>
                </b-col>
                <b-col cols="12" class="mb-32">
                  <h5>You are onboarded for:</h5>
                  <b-list-group>
                    <b-list-group-item
                      v-for="item in registration.migration.onboardedFor"
                      :key="item"
                    >
                      {{ item }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    type="button"
                    variant="primary-1"
                    class="w-100 text-dark"
                    @click.prevent="toLogin()"
                  >
                    Login
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </Transition>
        </b-col>
      </b-row>
    </b-col>
    <!-- Old applications found modal -->
    <b-modal
      id="old-applications-found-modal"
      title="We've found your old application! 🎉"
      hide-footer
    >
      <p>
        You will now be able to check your data in the next steps, you can
        always update them later.
        <br /><br />
        Your account will be created and if you are onboarded for one or more
        games you will have direct access to the portal once your account is
        created!
      </p>
      <b-button
        type="button"
        variant="primary-1"
        class="w-100 text-white"
        @click.prevent="closeMigrationModal()"
      >
        Continue
      </b-button>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormSelect,
  BFormRadio,
  BListGroup,
  BListGroupItem,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupText,
  BInputGroupAppend,
  BInputGroupPrepend,
  BAvatar,
  BAlert,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import LeftContentRegister from "../LeftContentRegister.vue";
import WelcomeText from "./welcomeText.json";
import CountryList from "./countryList.json";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import discordServices from "../../../../services/discord.services";
import authService from "../../../../services/auth.service";
import discordRoles from "../../../../store/data/discordRoles.json";
import lowauthHeader from "../../../../services/lowauth-header";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormSelect,
    BFormRadio,
    LeftContentRegister,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BInputGroupPrepend,
    BAvatar,
    BAlert,
    BSpinner,
    BModal,
  },
  data() {
    return {
      step: 1,
      registration: {
        referral: null,
        migration: {
          isMigrated: false,
          oldApplication: [],
          onboardedFor: [],
        },
        stepOne: {
          // Discord link/login
          discordid: "",
          discordusername: "",
          discorddiscriminator: "",
          discordavatar: "",
          guildroles: [],
        },
        stepTwo: {
          // Welcome
          welcomeStatus: false,
        },
        stepThree: {
          // More details
          firstName: "",
          lastName: "",
          userName: "",
          phoneNumber: "",
          country: "",
          email: "",
          password: "",
          isAdmin: false,
          onboardStatus: "submitted",
          isOnboarded: false,
          userUUID: "",
          createdAt: "",
        },
        stepFour: {
          // Games interested in
          gamesInterestedIn: [],
        },
        stepFive: {
          walletMetamask: "",
          walletRonin: "",
          walletSolana: "",
          walletWax: "",
        },
        stepSix: {
          // Your reason why
          reason: "",
        },
        stepSeven: {
          // Well being (nothing here for now)
        },
        stepEight: {
          // Additional information
          wheredidyouhearaboutus: "",
          nftGamesBefore: "",
          scholarshipBefore: "",
          scholarshipBeforeName: "",
          gameKnowledge: "",
          referralDiscordUsername: "",
        },
      },
      discordTest: [],
      selected: [],
      options: [
        { text: "Sunflower Land", value: "sunflowerland" },
        { text: "Million on Mars", value: "milliononmars" },
        { text: "Genopets", value: "genopets" },
        { text: "Legends of Venari", value: "legendsofvenari" },
        { text: "Honeyland", value: "honeyland" },
        { text: "Axie Infinity", value: "axieinfinity" },
        { text: "Illuvium", value: "illuvium" },
        { text: "Gods Unchained", value: "godsunchained" },
        { text: "Bigtime", value: "bigtim" },
      ],
      wheredidyouhearaboutus: [
        { text: "Discord", value: "discord" },
        { text: "Twitch", value: "twitch" },
        { text: "Youtube", value: "youtube" },
        { text: "Reddit", value: "reddit" },
        { text: "Twitter", value: "twitter" },
        { text: "Facebook", value: "facebook" },
        { text: "Instagram", value: "instagram" },
        { text: "Google", value: "google" },
        { text: "Other", value: "other" },
      ],
      gameKnowledge: [
        { text: "No knowledge at all", value: "1" },
        { text: "I understand how the game works", value: "2" },
        { text: "I consider myself a Noob", value: "3" },
        { text: "I am good at this game", value: "4" },
        { text: "I know everything", value: "5" },
      ],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  async mounted() {
    if(this.$route.query.error) {
      this.$bvToast.toast("Account not found, please register", {
        title: "Error",
        variant: "danger",
        solid: true,
      });
    }

    if (this.$route.query.code) {
      this.loadingDiscord();
      const code = this.$route.query.code;
      var discordId = "";
      var isDuplicated = false;
      var isError = false;
      await discordServices.Registration(code).then((response) => {
        if (!response.success) {
          this.DiscordError(response.message);
          isError = true;
          return;
        }
        this.registration.stepOne.discordid = response.data.user.id;
        discordId = response.data.user.id;
        this.registration.stepOne.discordusername = response.data.user.username;
        this.registration.stepOne.discorddiscriminator =
          response.data.user.discriminator;
        this.registration.stepOne.discordavatar =
          "https://cdn.discordapp.com/avatars/" +
            response.data.user.id +
            "/" +
            response.data.user.avatar +
            ".png" || "";
        this.registration.stepOne.guildroles = response.data.roles;

        // Pre define stepThree
        this.registration.stepThree.userName = response.data.user.username;
      });

      this.$router.replace({
        path: this.$route.path,
        query: {},
      });

      await authService.checkDuplication(discordId).then((res) => {
        if (res.success) {
          if (res.isDuplicated) {
            this.$router.push("/login?error=alreadyregistered");
            //this.DiscordError("You already have an account. Please login.");
            isDuplicated = true;
            isError = true;
            return;
          }
        } else {
          this.DiscordError(res.message);
          isDuplicated = false;
          isError = true;
          return;
        }
      });

      if (!isDuplicated && !isError) {
        await authService.handleMigration(discordId).then((res) => {
          if (res.success) {
            this.registration.migration.isMigrated = true;
            this.$bvModal.show("old-applications-found-modal");
            this.registration.migration.oldApplication.push(res.data.data);

            // Check for what game(s) the user has been onboarded for
            var guildRoles = this.registration.stepOne.guildroles;
            var memberRoles = discordRoles.games.member;
            // Check if guildRoles contains any of the memberRoles
            for (var j in guildRoles) {
              if (memberRoles[guildRoles[j]]) {
                this.registration.migration.onboardedFor.push(
                  memberRoles[guildRoles[j]]
                );
              }
            }
            /*console.log(
              this.registration.stepOne.discordusername +
                " is onboarded for: " +
                this.registration.migration.onboardedFor
            );*/

            // Now we add the data to the registration object
            var resData = res.data.data;
            this.registration.stepThree.firstName = resData.firstName;
            this.registration.stepThree.lastName = resData.lastName;
            this.registration.stepThree.phoneNumber = resData.phoneNumber;
            this.registration.stepThree.country = resData.country;
            this.registration.stepThree.email = resData.email;

            if (this.registration.migration.onboardedFor.length > 0) {
              this.registration.stepThree.onboardStatus = "approved";
              this.registration.stepThree.isOnboarded = true;
            } else {
              this.registration.stepThree.onboardStatus = "submitted";
              this.registration.stepThree.isOnboarded = false;
            }

            this.registration.stepFour.gamesInterestedIn =
              resData.gamesInterestedIn;

            this.registration.stepFive.walletMetamask = resData.walletMetamask;
            this.registration.stepFive.walletRonin = resData.walletRonin;
            this.registration.stepFive.walletSolana = resData.walletSolana;
            this.registration.stepFive.walletWax = resData.walletWax;

            this.registration.stepSix.reason = resData.reasonWhy;

            this.registration.stepEight.wheredidyouhearaboutus =
              resData.hearAboutUs;
            this.registration.stepEight.nftGamesBefore = resData.nftGamesBefore;
            this.registration.stepEight.scholarshipBefore =
              resData.scholarBefore;
            this.registration.stepEight.scholarshipBeforeName =
              resData.scholarBeforeName;
            this.registration.stepEight.gameKnowledge = resData.gameKnowledge;

            //console.log(res.data);
            this.step = 2;
          } else {
            this.registration.migration.isMigrated = false;
            this.step = 2;
          }
        });
      }

    }
  },
  methods: {
    closeMigrationModal() {
      this.$bvModal.hide("old-applications-found-modal");
    },
    toLogin() {
      this.$router.push("/login");
    },
    prev() {
      this.step--;
    },
    refresh() {
      window.location.reload();
    },
    next() {
      if (this.step == 3) {
        if (
          this.registration.stepThree.firstName == "" ||
          this.registration.stepThree.lastName == "" ||
          this.registration.stepThree.userName == "" ||
          this.registration.stepThree.phoneNumber == "" ||
          this.registration.stepThree.country == "" ||
          this.registration.stepThree.email == "" ||
          this.registration.stepThree.password == ""
        ) {
          this.registrationError("Please fill out all the inputs");
          return;
        }
      }

      if (this.step == 4) {
        var selected = this.registration.stepFour.gamesInterestedIn;
        if (selected.length == 0) {
          this.registrationError("Please select at least one game");
          return;
        }
      }

      if (this.step == 5) {
        if(this.registration.stepFour.gamesInterestedIn.includes(
            'sunflowerland'
          ) ||
          this.registration.stepFour.gamesInterestedIn.includes(
            'legendsofvenari'
          ) ||
          this.registration.stepFour.gamesInterestedIn.includes(
            'illuvium'
          ) ||
          this.registration.stepFour.gamesInterestedIn.includes(
            'godsunchained'
          ) ||
          this.registration.stepFour.gamesInterestedIn.includes(
            'bigtime'
          )) {
          if (this.registration.stepFive.walletMetamask == "") {
            this.registrationError("Please fill out your Metamask wallet");
            return;
          }
          }

        if(this.registration.stepFour.gamesInterestedIn.includes(
            'axieinfinity'
          )) {
          if (this.registration.stepFive.walletRonin == "") {
            this.registrationError("Please fill out your Ronin wallet");
            return;
          }
        }

        if(this.registration.stepFour.gamesInterestedIn.includes(
                          'genopets'
                        ) ||
                        this.registration.stepFour.gamesInterestedIn.includes(
                          'honeyland'
                        )) {
          if (this.registration.stepFive.walletSolana == "") {
            this.registrationError("Please fill out your Solana wallet");
            return;
          }
        }

        if(this.registration.stepFour.gamesInterestedIn.includes(
                          'milliononmars'
                        )) {
          if (this.registration.stepFive.walletWax == "") {
            this.registrationError("Please fill out your WAX wallet");
            return;
          }
        }

      }

      if (this.step == 6) {
        if (this.registration.stepSix.reason == "") {
          this.registrationError("Please provide your reason");
          return;
        }
      }

      this.step++;
    },
    submit() {
      if (this.step == 8) {
        if (
          this.registration.stepEight.wheredidyouhearaboutus == "" ||
          this.registration.stepEight.nftGamesBefore == "" ||
          this.registration.stepEight.scholarshipBefore == "" ||
          this.registration.stepEight.gameKnowledge == ""
        ) {
          this.registrationError("Please fill out all the inputs");
          return;
        }

        // declare date as current timestamp
        var date = new Date();
        var timestamp = date.getTime();

        this.registration.stepThree.createdAt = timestamp;
      }

      // create UUID
      this.registration.stepThree.userUUID = uuidv4();

      axios
        .post("https://api-unp.sacul.cloud/auth/register", this.registration, {
          headers: lowauthHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            if (this.registration.migration.isMigrated) {
          this.step = 10;
        } else {
          this.step = 9;
        }
            this.$confetti.start();
            setTimeout(() => {
              this.$confetti.stop();
            }, 5000);
          } else {
            this.registrationError("Something went wrong");
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    discordLogin() {
      const redirectURL = {
        prod: "https://discord.com/api/oauth2/authorize?client_id=1003403624975695932&redirect_uri=https%3A%2F%2Fmember.undisputednoobs.com%2Fregister&response_type=code&scope=identify%20guilds.members.read",
        dev: "https://discord.com/api/oauth2/authorize?client_id=1003403624975695932&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fregister&response_type=code&scope=identify%20guilds.members.read",
      };

      window.location.href = redirectURL.prod;
    },
    joinDiscord() {
      window.open("https://discord.gg/undisputednoobs", "_blank");
    },
    loadingDiscord() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;
      // Increment the toast count
      this.count++;
      // Create the message
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        " Retrieving your Discord data... Please wait... ",
        h("b-spinner", { class: ["align-middle"] }),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
        [
          h("strong", { class: "mr-2" }, "Account Registration"),
          h("small", { class: "ml-auto text-italics" }, "Just now"),
        ]
      );
      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        autoHideDelay: 500,
      });
    },
    DiscordError(err) {
      this.$bvToast.toast(err, {
        title: `Discord Error`,
        variant: "danger",
        solid: true,
      });
    },

    registrationError(err) {
      this.$bvToast.toast(err, {
        title: `Registration Error`,
        variant: "danger",
        solid: true,
      });
    },

    end() {
      this.step = 9;
    },

    showModal(name) {
      this.$bvModal.show(name);
    },
  },
  props: {
    message: {
      type: String,
      default: WelcomeText.Text,
    },
    countryList: {
      type: Array,
      default: CountryList,
    },
  },
};
</script>


